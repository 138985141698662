import axios from '@axios'
const APP_URL = process.env.VUE_APP_URL

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchModules(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/module`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchModule(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/module/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addModule(ctx, moduleData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${APP_URL}/module`, { module: moduleData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
   
    updateModule(ctx, { id, moduleData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${APP_URL}/module/${id}`, { module: moduleData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeModule(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${APP_URL}/module/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
