import { translatableText } from "@/@core/utils/utils";
import i18n from "@/libs/i18n";

export const loginErrorHandle = async (error) => {
  let message = "";
  if (!error) message = i18n.t('Error')
  // The request was made and the server responded with a status code
  // that falls out of the range of 2xx
  else if (error.response) {
    const key = "messages." + error.response.data.key;
    if (error.response.data.key && keyTranslationExist(key))
      message = i18n.t(key);
    else message = error.response.data.message;
  }
  // The request was made but no response was received
  else if (error.request) {
    message =  i18n.t('not_establish_communication_server');
  }
  // Something happened in setting up the request that triggered an Error
  else {
    message = error.message;
  }
  return message;
};

/**
 * If the translation exists in the translation objects, returns true
 * @param key
 * @returns {boolean}
 */
const keyTranslationExist = (key) => {
  return i18n.te(key) === true;
};
